@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Coconat';
  src: url('https://firebasestorage.googleapis.com/v0/b/annapurnasweetshop.appspot.com/o/fonts%2FCoconat-Regular.woff2?alt=media&token=f141b4b5-eb48-4515-92cf-805625158716');
  font-weight: 400;
}
@font-face {
  font-family: 'Satoshi-Regular';
  src: url('https://firebasestorage.googleapis.com/v0/b/annapurnasweetshop.appspot.com/o/fonts%2FSatoshi-Variable.woff2?alt=media&token=7c306132-1d7e-45f5-bd6e-b9dde52a0f23');
  font-weight: 600;
}
.font-coconat{
  font-family: 'Coconat'!important;
}
.satoshi-regular {
  font-family: 'Satoshi-Regular';
  font-weight: 600;
}
.cursive {
  font-family: 'Dancing Script', cursive;
}
:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
a:hover {
  color: black;
}