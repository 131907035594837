.url-text-box {
    outline: none;
    box-shadow: none !important;
    border: 1px solid #ccc !important;
}

.url-form .input-group-text {
    background-color: white;
}

.nav-items {
    list-style: none;
    display: table;
    text-align: center;
}

.nav-items>li {
    display: table-cell;
    position: relative;
    cursor: pointer;
    font-size: 15px;
    /* padding: 15px 0; */
}

.nav-items>a {
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    display: inline-block;
    padding: 5px 2px;
    margin-left: 20px;
    position: relative;
}

.nav-items>a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #CD9951;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.nav-items>a:hover:after {
    width: 100%;
    left: 0;
}