.card-rating-text {
  color: #B4690E;
  /* font-weight: 900; */
}

.card-star-rating {
  display: inline-block;
  unicode-bidi: bidi-override;
  color: #c7c7c7;
  font-size: 22px;
  height: 25px;
  width: auto;
  margin: 0;
  position: relative;
  padding: 0;
}

.card-star-rating-upper {
  color: #E59819;
  padding: 0;
  position: absolute;
  z-index: 1;
  display: flex;
  top: 0;
  left: 10;
  overflow: hidden;
}

.card-star-rating-lower {
  padding: 0;
  display: flex;
  z-index: 0;
  top: 0;
  left: 0;
}