body {
    font-family: 'Coconat', sans-serif;
    background-color: #FFFDF9;
    overflow-x: hidden;
}

.product-card {
    background: rgba(255, 241, 230, 0.25) !important;
    /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 )!important; */
    backdrop-filter: blur(4px) !important;
    -webkit-backdrop-filter: blur(4px) !important;
    border-radius: 10px !important;
    border: 1px solid rgba(255, 255, 255, 0.18) !important;
}

/* Carousel Buttons */
.react-multiple-carousel__arrow {
    background-color: #CD9951 !important;
}

.btn-primary:focus,
.btn-primary:active {
    box-shadow: none !important;
    outline: 0px !important;
}

.btn-danger:focus,
.btn-danger:active {
    box-shadow: none !important;
    outline: 0px !important;
}

.btn-success:focus,
.btn-success:active {
    box-shadow: none !important;
    outline: 0px !important;
}

/* Styles for global-scrollbar */
.global-scrollbar::-webkit-scrollbar-track {
    background-color: white;
}

.global-scrollbar::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
}

.global-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 50px;
    width: 8px;
    height: 60px;
    background-color: #D9D9D9;
}

/* Marquee text */

.marquee {
    margin: 0px auto;
    padding: 3px;
    white-space: nowrap;
    overflow: hidden;
    background-color: rgb(218, 4, 40);
    width: 100%;
}

.marquee p {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(255, 255, 255);
    font-family: Coconat;
    font-weight: 400;
    line-height: 120%;
    padding-left: 100%;
    animation-name: marquee-animation;
    animation-iteration-count: infinite;
    animation-duration: 25s;
    animation-timing-function: linear;
}

@keyframes marquee-animation {
    0% {
        transform: translate(0px, 0px);
    }

    100% {
        transform: translate(-100%, 0px);
    }
}

/* Photo Carousel */
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23CD9951' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23CD9951' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
.carousel-control-prev {
    width: 6%!important;
}
.carousel-control-next {
    width: 6%!important;
}
.carousel-indicators{
    bottom: -40px!important;
}
.carousel-indicators [data-bs-target] {
    background-color: #CD9951;
    height: 6px!important;
}

@media only screen and (max-width: 600px) {
    .carousel-indicators [data-bs-target]{
        height: 3px!important;
    }
}