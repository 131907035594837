.product-card {
    background-color: #fff;
    border: none;
    border-radius: 10px;
    width:100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.image-container {
    position: relative;
}

.thumbnail-image {
    border-radius: 10px !important;
    width: 100%;
    /* height: 25vw; */
    /* object-fit: cover; */
}

.discount-per {
    background-color: #DA0428;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 20px;
    border-radius: 6px;
    color: #fff;
}

.wishlist {
    height: fit-content;
    width: fit-content;
    /* background-color: #960C4A; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.first {
    position: absolute;
    width: 100%;
    padding: 9px;
}

.new-price {
    font-weight: bold;
    color: #DA0428;
    font-family: sans-serif;
}

.old-price {
    font-weight: bold;
    color: grey;
}

.rating-number {
    color: grey;
}

.buy-btn {
    border: none;
    border-radius: 7px;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 1.3px;
    color: #fff;
    background-color: #DA0428;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
}