.quantity-toggle {
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    padding: 3px;
    border-radius: 5px;
    border:2px solid lightblue;
    cursor:pointer;
}

.quantity-toggle:hover {
    opacity: 0.8;
}