
  .dropup button {
    background: transparent;
    border: none;
  }

/* Styles for sidebar scroolbar */

.sidebar-nav::-webkit-scrollbar-track {
    background-color: #0D0D0D;
}

.sidebar-nav::-webkit-scrollbar {
    width: 10px;
    background-color: #6f6f6f;
}

.sidebar-nav::-webkit-scrollbar-thumb {
    border-radius: 50px;
    width: 10px;
    height: 60px;
    background-color: #6f6f6f;
}